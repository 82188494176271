import React, { Component } from 'react';
import { Form } from 'react-redux-form';
import { TEXT } from 'locales/translations';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EmailTextField, GiftCouponField, NameTextField } from 'components';
import { ImplicitTermsAcceptance } from 'Component';
import BkkTermsField from './BkkTermsField';
import { registerUserRequest } from 'redux/actions';
import { isRegisterError } from 'redux/query';
import { Button, Link } from 'Common';
import { ThemeContainer } from 'Container';
import { themeUniversal } from 'Theme';
import { Paragraph } from 'Common';

class RegisterFormBox extends Component {
  registerUser = ({ firstname, email }) => {
    this.props.registerUserRequest({
      firstname,
      email,
      sendDeepLinkOTP: false
    });
  };
  render() {
    const { headline, subline, requiredTerms, showLoginForm, hasRegisterError } = this.props;
    return (
      <section id="form-register-section-container">
        <ThemeContainer theme={themeUniversal}>
          <h2>{headline}</h2>
          <p className="sign-in-text card-link text-center">
            {TEXT.registration.alreadyUserHint}
            <Link onClick={showLoginForm.bind(this)} color="green">
              {TEXT.registration.loginHint}
            </Link>
          </p>
          <p className="newsletter-disclaimer">{subline}</p>
          <Form className="wrapper" model="entities.forms.user" onSubmit={this.registerUser} hideNativeErrors>
            <NameTextField />
            {hasRegisterError && <span className="fb-error">{TEXT.registration.email_already_exists}</span>}
            <EmailTextField />
            <GiftCouponField
              showRequiredHint={false}
              id="giftcouponcode"
              placeholder="Zugangs-Code"
              messages={{
                validLength: 'Zugangs-Code ist nicht gültig',
                valueMissing: 'Bitte gib den Zugangs-Code ein.'
              }}
            />
            {requiredTerms && <BkkTermsField />}
            <Paragraph className="extra-small legal-newsletter-info">{TEXT.formsGeneral.legalNewsletterInfo}</Paragraph>

            <Button dataCyName="submit-button">{TEXT.registration.register_btn}</Button>
          </Form>
          <ImplicitTermsAcceptance linkColor="green" />
        </ThemeContainer>
      </section>
    );
  }
}

RegisterFormBox.propTypes = {
  showLoginForm: PropTypes.func.isRequired,
  hasRegisterError: PropTypes.bool.isRequired,
  registerUserRequest: PropTypes.func.isRequired,
  headline: PropTypes.string.isRequired,
  subline: PropTypes.string.isRequired,
  requiredTerms: PropTypes.bool.isRequired
};

RegisterFormBox.defaultProps = {
  hasRegisterError: false,
  requiredTerms: true
};

const mapStateToProps = (state) => ({
  hasRegisterError: isRegisterError(state)
});

export default connect(mapStateToProps, { registerUserRequest })(RegisterFormBox);
