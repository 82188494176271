import React, { useEffect, useState, useRef } from 'react';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceCompanies } from 'redux/query';
import { getInsuranceCompaniesRequest } from 'redux/actions';

export const SelectHealthInsuranceCompany = ({ onChange = () => {} }) => {
  const dispatch = useDispatch();
  const typeaheadRef = useRef(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    dispatch(getInsuranceCompaniesRequest());
  }, []);

  const insuranceCompanies = useSelector(getInsuranceCompanies);

  const sortedInsuranceCompanies = insuranceCompanies.slice().sort((a, b) => a.name.localeCompare(b.name));
  const cooperationPartnersWithHeadline = [
    { name: 'Krankenkassen ohne Vorauszahlung', isHeadline: true },
    ...sortedInsuranceCompanies.filter((company) => company.cooperationPartner)
  ];
  const sortedInsuranceCompaniesWithHeadline = [{ name: 'Alle Krankenkassen', isHeadline: true }, ...sortedInsuranceCompanies];
  const allInsuranceCompanies = [...cooperationPartnersWithHeadline, ...sortedInsuranceCompaniesWithHeadline];

  const handleOnChange = (company) => {
    if (!company?.[0]) {
      onChange(null);
      setSelectedCompany(null);
      return;
    }
    const refunds = (company?.[0]?.reductionRate * 75.0) / 100;
    company = { ...company[0], refunds };
    setSelectedCompany(company);
    onChange(company);
  };

  const handleArrowClick = () => {
    if (typeaheadRef.current) {
      typeaheadRef.current.focus();
    }
  };

  return (
    <div className="wrapper">
      <div className="dropdown-arrow-klick-area" onClick={handleArrowClick} />
      <Typeahead
        ref={typeaheadRef}
        maxResults={400}
        id="basic-typeahead-single"
        labelKey="name"
        className="dropdown-toggle"
        onChange={handleOnChange}
        options={allInsuranceCompanies}
        placeholder="Wähle deine Krankenkasse"
        selected={selectedCompany ? [selectedCompany] : []}
        allowNew={false}
        paginationText="Weitere anzeigen"
        renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
          const items = results.map((result, index) => (
            <MenuItem key={index} option={result} position={index} className={result.isHeadline ? 'headline' : ''}>
              {result.name}
            </MenuItem>
          ));
          return (
            <Menu {...menuProps} emptyLabel="Krankenkasse nicht gefunden">
              {items}
            </Menu>
          );
        }}
      />
    </div>
  );
};
