import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { addLocaleData, IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
import de from 'react-intl/locale-data/de';
import { ReactNotifications } from 'react-notifications-component';
import { CONFIG, environment, VERSION } from 'config';
import {
  AccessibilityPage,
  AppImprintPage,
  AppPrivacyPage,
  AppTermsPage,
  AuthenticationPage,
  BuyGift,
  Checkout,
  CheckoutConfirm,
  ExternalFacebookPrivacyPage,
  ExternalHealthPage,
  ExternalPrivacyPolicyPage,
  ExternalTermsPage,
  HealthBenefitPage,
  HealthPage,
  PaymentError,
  PrivacyPage,
  PurchasePage,
  SubscriptionUpgradePage,
  TermsPage,
  ThankYou,
  ThankYouGift,
  ThankYouLoggedOut,
  ThankYouPurchaseAssociated,
  ThankYouUpgrade,
  InsuranceCooperationAuthenticationPage,
  TrustPortalPage
} from 'Page';
import {
  CategoryContainer,
  CoursePlayerContainer,
  LibraryContainer,
  PrivateRouteContainer,
  SinglePlayerContainer,
  VoucherRouteContainer,
  WithdrawCancelationContainer
} from './containers';
import {
  B2BLandingPage,
  BkkLandingPage,
  EmailConfirmation,
  EmailConfirmationError,
  FallbackAppOtpPage,
  FallbackAppPage,
  GothaerLandingPage,
  HealthAdvisorPage,
  HealthInsuranceMarketingPage,
  HealthInsuranceSurveyMaybe,
  HealthInsuranceSurveyRight,
  HealthInsuranceSurveyWrong,
  Home,
  Imprint,
  KaifuLandingPage,
  Licht,
  MkkLandingPage,
  NotFoundPage,
  PodcastLandingPage,
  Profile,
  ResiliencePage,
  SignInUserWithOnetimePasswordBox,
  Stress,
  TerminateUserAbo,
  TerminateUserAboSuccess,
  UpgradeError,
  XingLandingPage,
  XingProJobsLandingPage
} from './components';
import { Footer, Navigation } from 'Component';
import { ThemeProvider } from 'Container';
import { browserHistory, Persistor, store } from 'redux/configureStore';
import { ROUTES } from 'utils/constants';
import { translations } from './locales/translations';
import { defaultLocale } from './locales/default';
import { pushDataLayerPageView } from 'components/GtmHelper';
import AuthenticationContainer from 'containers/Authentication/AuthenticationContainer';
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop';
import { PageTitleContainer } from './containers/PageTitleContainer/PageTitleContainer';
import { BigDirectLandingPage } from './components/Landingpages/BigDirect/BigDirectLandingPage';
import { IkkClassicLandingPage } from './components/Landingpages/IkkClassic/IkkClassicLandingPage';
import { ViactivLandingPage } from './components/Landingpages/Viactiv/ViactivLandingPage';
import { HkkLandingPage } from './components/Landingpages/Hkk/HkkLandingPage';
import { VividaBkkLandingPage } from './components/Landingpages/VividaBkk/VividaBkkLandingPage';
import { HealthInsurancePage } from './components/Landingpages/HealthInsurance/HealthInsurancePage';
import { DakLandingPage } from './components/Landingpages/DakGesundheit/DakLandingPage';
import { DakBgmLandingPage } from './components/Landingpages/DakGesundheit/DakBgmLandingPage';
import { useHealthInsuranceBanner } from 'hookApi';
import { SalusBkkLandingPage } from './components/Landingpages/SalusBkk/SalusBkkLandingPage';
import { TkLandingPage } from './components/Landingpages/Tk/TkLandingPage';
import { AokBayernLandingPage } from './components/Landingpages/AokBayern/AokBayernLandingPage';

addLocaleData([...de]);

export const App = () => {
  useEffect(() => {
    pushDataLayerPageView(window.location.pathname);

    Bugsnag.start({
      apiKey: CONFIG.BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: environment,
      appVersion: VERSION,
      enabledErrorTypes: {
        unhandledExceptions: true,
        unhandledRejections: false
      }
    });
  }, []);

  useHealthInsuranceBanner();

  const locale = defaultLocale;

  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
          <ConnectedRouter history={browserHistory}>
            <ScrollToTop />
            <AuthenticationContainer />
            <PageTitleContainer />
            <ThemeProvider>
              <ReactNotifications />
              <Navigation />
              <Switch>
                <Route exact path={ROUTES.home} component={Home} />
                <Route path={ROUTES.login} component={AuthenticationPage} />
                <Route path={ROUTES.signup} component={AuthenticationPage} />
                <Route path={ROUTES.plans} component={PurchasePage} />
                <Route path={ROUTES.checkout} component={Checkout} />
                <Route path={ROUTES.checkoutConfirm} component={CheckoutConfirm} />
                <VoucherRouteContainer path={ROUTES.profile} component={Profile} />
                <VoucherRouteContainer path={ROUTES.voucher} component={Profile} />
                <VoucherRouteContainer path={ROUTES.deeplinkConfirmMail} component={Profile} />
                <Route path={ROUTES.imprint} component={Imprint} />
                <Route path={ROUTES.thankyougift} component={ThankYouGift} />
                <Route path={ROUTES.confirmMailCode} component={SignInUserWithOnetimePasswordBox} />
                <Route path={ROUTES.fallbackAppOtp} component={FallbackAppOtpPage} />
                <PrivateRouteContainer path={ROUTES.thankyou} component={ThankYou} />
                <PrivateRouteContainer path={ROUTES.thankyouupgrade} component={ThankYouUpgrade} />
                <Route path={ROUTES.thankyouloggedout} component={ThankYouLoggedOut} />
                <Route path={ROUTES.thankyoupurchaseassociated} component={ThankYouPurchaseAssociated} />
                <Route path={ROUTES.paymenterror} component={PaymentError} />
                <Route path={ROUTES.upgradeerror} component={UpgradeError} />
                <Route path={ROUTES.licht} component={Licht} />
                <Redirect from={ROUTES.lichtShort} to={ROUTES.licht} />
                <Route path={ROUTES.stress} component={Stress} />
                <Redirect from={ROUTES.stressShort} to={ROUTES.stress} />
                <Route path={ROUTES.buyGift} component={BuyGift} />
                <Route path={ROUTES.category} component={CategoryContainer} />
                <Route path={ROUTES.library} component={LibraryContainer} />
                <Route path={ROUTES.webplayer} component={CoursePlayerContainer} />
                <Route path={ROUTES.singlePlayer} component={SinglePlayerContainer} />
                <Route path={ROUTES.kaifu} component={KaifuLandingPage} />
                <Redirect from={ROUTES.kaifuShort} to={ROUTES.kaifu} />
                <Route path={ROUTES.podcast} component={PodcastLandingPage} />
                <Route path={ROUTES.email_confirmation} component={EmailConfirmation} />
                <Route path={ROUTES.email_confirmation_error} component={EmailConfirmationError} />
                <Route path={ROUTES.upgrade} component={SubscriptionUpgradePage} />
                <Route path={ROUTES.withdrawCancelation} component={WithdrawCancelationContainer} />
                <Route path={ROUTES.company} component={B2BLandingPage} />
                <Route path={ROUTES.xing} component={XingLandingPage} />
                <Redirect from={ROUTES.xingShort} to={ROUTES.xing} />
                <Route path={ROUTES.xingProJobs} component={XingProJobsLandingPage} />
                <Redirect from={ROUTES.xingProJobsShort} to={ROUTES.xingProJobs} />
                <Route path={ROUTES.gothaer} component={GothaerLandingPage} />
                <Redirect from={ROUTES.gothaerShort} to={ROUTES.gothaer} />
                <Route path={ROUTES.bkk} component={BkkLandingPage} />
                <Redirect from={ROUTES.bkkShort} to={ROUTES.bkk} />
                <Route path={ROUTES.mkk} component={MkkLandingPage} />
                <Redirect from={ROUTES.mkkShort} to={ROUTES.mkk} />
                <Route path={ROUTES.dak} component={DakLandingPage} />
                <Redirect from={ROUTES.dakShort} to={ROUTES.dak} />
                <Route path={ROUTES.bigDirect} component={BigDirectLandingPage} />
                <Route path={ROUTES.salusBkk} component={SalusBkkLandingPage} />
                <Route path={ROUTES.tk} component={TkLandingPage} />
                <Route path={ROUTES.aokBayern} component={AokBayernLandingPage} />
                <Route path={ROUTES.dakBgm} component={DakBgmLandingPage} />
                <Redirect from={ROUTES.dakBgmShort} to={ROUTES.dakBgm} />
                <Route path={ROUTES.ikkClassic} component={IkkClassicLandingPage} />
                <Route path={ROUTES.hkk} component={HkkLandingPage} />
                <Route path={ROUTES.viactiv} component={ViactivLandingPage} />
                <Route path={ROUTES.vividaBkk} component={VividaBkkLandingPage} />
                <Redirect from={ROUTES.viactivShort} to={ROUTES.viactiv} />
                <Route path={ROUTES.terminateSuccess} component={TerminateUserAboSuccess} />
                <Route path={ROUTES.terminate} component={TerminateUserAbo} />
                <Route path={ROUTES.fallbackApp} component={FallbackAppPage} />
                <Route path={ROUTES.fallbackAppView} component={FallbackAppPage} />
                <Route path={ROUTES.resilience} component={ResiliencePage} />
                <Route path={ROUTES.krankenkasseSurveyWrong} component={HealthInsuranceSurveyWrong} />
                <Route path={ROUTES.krankenkasseSurveyRight} component={HealthInsuranceSurveyRight} />
                <Route path={ROUTES.krankenkasseSurveyMaybe} component={HealthInsuranceSurveyMaybe} />
                <Route path={ROUTES.krankenkasse} component={HealthInsurancePage} />
                <Route path={ROUTES.stressreduktion} component={HealthInsuranceMarketingPage} />
                <Route path={ROUTES.healthAdvisor} component={HealthAdvisorPage} />
                <Route path={ROUTES.trustPortal} component={TrustPortalPage} />
                {/*external legacy pages, likely used in facebook, play store or app store settings*/}
                <Route path={ROUTES.externalLegacyFacebookPrivacy} component={ExternalFacebookPrivacyPage} />
                <Route path={ROUTES.externalLegacyPrivacyPolicy} component={ExternalPrivacyPolicyPage} />
                <Route path={ROUTES.externalLegacyTerms} component={ExternalTermsPage} />
                <Route path={ROUTES.externalLegacyHealth} component={ExternalHealthPage} />
                {/*External new pages, likely used in facebook, play store or app store settings*/}
                <Route path={ROUTES.externalFacebookPrivacy} component={ExternalFacebookPrivacyPage} />
                <Route path={ROUTES.externalPrivacy} component={ExternalFacebookPrivacyPage} />
                <Route path={ROUTES.externalPrivacyPolicy} component={ExternalPrivacyPolicyPage} />
                <Route path={ROUTES.externalTerms} component={ExternalTermsPage} />
                <Route path={ROUTES.externalHealth} component={ExternalHealthPage} />
                {/*legacy routes for app legal pages, they end with *.html*/}
                <Route path={ROUTES.appLegacyPrivacy} component={AppPrivacyPage} />
                <Route path={ROUTES.appLegacyTerms} component={AppTermsPage} />
                <Route path={ROUTES.appLegacyImprint} component={AppImprintPage} />
                {/*legacy routes for webapp legal pages, they end with *.html */}
                <Route path={ROUTES.webappLegacyAccessibility} component={AccessibilityPage} />
                <Route path={ROUTES.webappLegacyHealth} component={HealthPage} />
                <Route path={ROUTES.webappLegacyHealthBenefit} component={HealthBenefitPage} />
                <Route path={ROUTES.webappLegacyPrivacy} component={PrivacyPage} />
                <Route path={ROUTES.webappLegacyTerms} component={TermsPage} />
                <Route path={ROUTES.insuranceCooperationAuthentication} component={InsuranceCooperationAuthenticationPage} />
                {/*new routes for web legal pages*/}
                <Route path={ROUTES.webAccessibility} component={AccessibilityPage} />
                <Route path={ROUTES.webHealth} component={HealthPage} />
                <Route path={ROUTES.webHealthBenefit} component={HealthBenefitPage} />
                <Route path={ROUTES.webPrivacy} component={PrivacyPage} />
                <Route path={ROUTES.webTerms} component={TermsPage} />

                <Route component={NotFoundPage} />
              </Switch>
              <Footer />
            </ThemeProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
};

// allow cypress to watch the store
if (window.Cypress) {
  window.store = store;
}
