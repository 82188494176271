import * as types from 'redux/actions/actionTypes';
import {
  ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE,
  ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST,
  ATTEND_INSURANCE_COOPERATION_USER_DATA_SUCCESS,
  AUTHENTICATE_WITH_GIS_FAILURE,
  AUTHENTICATE_WITH_GIS_REQUEST,
  AUTHENTICATE_WITH_GIS_SUCCESS,
  PERSIST_HEALTH_INSURANCE_USER_DATA,
  REGISTER_USER_WITH_INSURANCE_NUMBER_FAILURE,
  REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST,
  REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  SEND_OTP_WITH_INSURANCE_NUMBER_FAILURE,
  SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST,
  SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS,
  SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_FAILURE,
  SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST,
  SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS,
  VALIDATE_INSURANCE_NUMBER_FAILURE,
  VALIDATE_INSURANCE_NUMBER_REQUEST,
  VALIDATE_INSURANCE_NUMBER_SUCCESS,
  RESET_USER_GUEST_STATE,
  SET_INSURANCE_PROVIDER,
  GET_USER_PAYMENT_INFOS_SUCCESS,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS,
  AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE,
  SUBMIT_CHECKOUT_SUCCESS,
  SUBMIT_CHECKOUT_REQUEST,
  CLEAR_SUBSCRIPTION_PLANS,
  CLEAR_STRIPE_PAYMENT_INFOS,
  CONFIRM_PAYMENT_METHOD_SUCCESS,
  SELECT_SUBSCRIPTION_PLAN,
  REGISTER_USER_GUEST_REQUEST,
  REGISTER_USER_GUEST_SUCCESS,
  REGISTER_USER_GUEST_FAILURE,
  GET_USER_SUBSCRIPTIONS_REQUEST,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_FAILURE,
  SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST,
  SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_SUCCESS,
  SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_FAILURE,
  SET_INSURANCE_TOKEN_EXPIRES_DATE
} from 'redux/actions';
import axiosError from '../axiosError';
import { removeFailedRequest, removeOpenRequest, ROUTES } from 'utils';
import { SUBSCRIPTION_PLAN_GROUPS } from 'utils/subscriptionPlanGroups';
import { User } from 'domain/enitity';
import { pushDataLayerUserType } from 'components/GtmHelper';
import UsersState from 'redux/stores/usersState';

const initialState = UsersState.getInitialState();

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGOUT_USER: {
      return initialState;
    }

    case SET_INSURANCE_PROVIDER: {
      return {
        ...state,
        insuranceProvider: action.payload.insuranceProvider
      };
    }

    case SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_REQUEST:
    case AUTHENTICATE_WITH_GIS_REQUEST:
    case REGISTER_USER_REQUEST: {
      return {
        ...state,
        openRequests: [...state.openRequests, action.type],
        failedRequests: removeFailedRequest(action, state),
        updatedAt: Date.now(),
        error: null
      };
    }

    case ATTEND_INSURANCE_COOPERATION_USER_DATA_REQUEST:
    case AUTHENTICATE_INSURANCE_COOPERATION_USER_REQUEST:
    case GET_USER_SUBSCRIPTIONS_REQUEST:
    case SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_REQUEST:
    case SEND_OTP_WITH_INSURANCE_NUMBER_REQUEST:
    case VALIDATE_INSURANCE_NUMBER_REQUEST: {
      return {
        ...state,
        zppAlreadyApplied: false,
        openRequests: [...state.openRequests, action.type],
        failedRequests: removeFailedRequest(action, state),
        updatedAt: Date.now(),
        error: null
      };
    }

    case REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST: {
      return {
        ...state,
        openRequests: [...state.openRequests, action.type],
        failedRequests: removeFailedRequest(action, state),
        updatedAt: Date.now(),
        userMetaData: action.payload.userMetaData,
        error: null
      };
    }

    case SELECT_SUBSCRIPTION_PLAN:
    case SUBMIT_CHECKOUT_REQUEST: {
      return {
        ...state,
        confirmedSubscription: initialState.confirmedSubscription,
        updatedAt: Date.now(),
        error: null
      };
    }

    case CONFIRM_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        paymentMethodAutoAssigned: action.payload.paymentMethodAutoAssigned,
        updatedAt: Date.now()
      };
    }

    case VALIDATE_INSURANCE_NUMBER_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now()
      };
    }

    case GET_USER_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          ...User({
            subscriptions: action.payload.subscriptions
          })
        }
      };
    }

    case SUBMIT_CHECKOUT_SUCCESS: {
      return {
        ...state,
        confirmedSubscription: {
          ...state.confirmedSubscription,
          confirmedPurchase: true,
          autoAssigned: action.payload.userAutoAssigned,
          isGiftCoupon: action.payload.selectedSubscriptionPlan.subscriptionPlanGroup === SUBSCRIPTION_PLAN_GROUPS.GIFT
        },
        updatedAt: Date.now()
      };
    }

    case CLEAR_STRIPE_PAYMENT_INFOS: {
      return {
        ...state,
        paymentMethodAutoAssigned: false,
        updatedAt: Date.now()
      };
    }

    case CLEAR_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        confirmedSubscription: initialState.confirmedSubscription,
        updatedAt: Date.now()
      };
    }

    case SEND_OTP_WITH_INSURANCE_NUMBER_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        isAuthed: false,
        isGuest: false,
        currentUser: {
          ...state.currentUser,
          ...User({
            email: action.payload.emailHint,
            insuranceNumber: action.payload.insuranceNumber,
            isAuthed: false,
            isGuest: false
          })
        }
      };
    }

    case ATTEND_INSURANCE_COOPERATION_USER_DATA_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.data.attendInsuranceCooperation.user)
        },
        zppAlreadyApplied: action.payload.data?.alreadyApplied || false
      };
    }

    case SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          ...User({
            oAuthTokenExpiresAt: action.payload.oAuthTokenExpiresAt
          })
        }
      };
    }

    case SET_INSURANCE_TOKEN_EXPIRES_DATE: {
      return {
        ...state,
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          ...User({
            oAuthTokenExpiresAt: action.payload.oAuthTokenExpiresAt
          })
        }
      };
    }

    case AUTHENTICATE_INSURANCE_COOPERATION_USER_SUCCESS:
    case SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        token: action.payload.token || state.token,
        isGuest: false,
        isAuthed: true,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.user)
        }
      };
    }

    case REGISTER_USER_WITH_INSURANCE_NUMBER_SUCCESS: {
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        failedRequests: removeFailedRequest({ type: REGISTER_USER_FAILURE }, state),
        updatedAt: Date.now()
      };
    }

    case ATTEND_INSURANCE_COOPERATION_USER_DATA_FAILURE:
    case AUTHENTICATE_INSURANCE_COOPERATION_USER_FAILURE:
    case REGISTER_USER_WITH_INSURANCE_NUMBER_FAILURE:
    case SEND_OTP_WITH_INSURANCE_NUMBER_FAILURE:
    case SIGN_IN_WITH_OTP_AND_INSURANCE_NUMBER_FAILURE:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        failedRequests: [...state.failedRequests, action.type],
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          b2bClientKeys: []
        },
        error: action.error?.[0]?.extensions || action.error
      };

    case SEND_INSURANCE_COOPERATION_OAUTH_TOKEN_FAILURE:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        failedRequests: [...state.failedRequests, action.type],
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          oAuthToken: undefined,
          oAuthTokenExpiresAt: undefined
        },
        error: action.error?.[0]?.extensions || action.error
      };

    case GET_USER_SUBSCRIPTIONS_FAILURE:
    case VALIDATE_INSURANCE_NUMBER_FAILURE:
      return {
        ...state,
        openRequests: removeOpenRequest([action, REGISTER_USER_WITH_INSURANCE_NUMBER_REQUEST], state),
        failedRequests: [...state.failedRequests, action.type],
        updatedAt: Date.now(),
        error: action.error?.[0]?.extensions || action.error
      };

    case types.GET_USER_PENDING:
    case types.UPDATE_USER_PENDING:
    case types.LOGIN_USER_PENDING:
    case types.REGISTER_USER_PENDING:
    case types.CHECK_CONFIRM_MAIL_CODE_PENDING:
    case types.REVOKE_CONFIRM_MAIL_CODE_PENDING:
      return {
        ...state,
        isFetching: true
      };

    case REGISTER_USER_GUEST_REQUEST: {
      return {
        ...state,
        isFetching: true,
        openRequests: [...state.openRequests, action.type],
        failedRequests: removeFailedRequest(action, state),
        updatedAt: Date.now(),
        error: null
      };
    }

    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.me)
        },
        isFetching: false,
        isGoogleUser: action.payload.result.data.me.isGoogleUser,
        isAppleUser: action.payload.result.data.me.isAppleUser,
        unconfirmedEmail: action.payload.result.data.me.unconfirmedEmail,
        error: undefined
      };
    }

    case types.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.updateUser.user)
        },
        isFetching: false,
        error: undefined
      };
    }
    case types.UPDATE_USER_MAIL_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        unconfirmedEmail: action.payload.result.data.requestEmailAddressChange.user.unconfirmedEmail,
        error: undefined
      };
    }
    case types.REVOKE_CONFIRM_MAIL_CODE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        unconfirmedEmail: action.payload.result.data.revokeEmailAddressChange.user.unconfirmedEmail,
        error: undefined
      };
    }

    case types.LOGIN_USER_SUCCESS: {
      pushDataLayerUserType('signin', 'email-signin');

      const token = action.payload.result.data.signInUser.token;
      return {
        ...state,
        token: token,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.signInUser.user)
        },
        isAuthed: true,
        isFetching: false,
        error: undefined,
        isGuest: false,
        isGoogleUser: false,
        isAppleUser: false,
        unconfirmedEmail: action.payload.result.data.signInUser.user.unconfirmedEmail
      };
    }

    case types.SIGN_IN_USER_OTP_VOUCHER_SUCCESS:
    case types.SIGN_IN_USER_OTP_SUCCESS: {
      pushDataLayerUserType('signin', 'email-signin');
      const token = action.payload.result.data.signInUserWithOnetimePassword.token;
      return {
        ...state,
        token: token,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.signInUserWithOnetimePassword.user)
        },
        isAuthed: true,
        isFetching: false,
        error: undefined,
        isGuest: false,
        isGoogleUser: false,
        isAppleUser: false,
        unconfirmedEmail: action.payload.result.data.signInUserWithOnetimePassword.user.unconfirmedEmail
      };
    }

    case types.LOGIN_USER_FAILURE: {
      const loginError = action.error[0].extensions.user.indexOf('authenticationFailed') !== 1;

      const GoogleAuthExpected = action.error[0].extensions.user.indexOf('googleAuthExpected') !== 1;

      return {
        ...state,
        isFetching: false,
        isLoginError: loginError,
        isGoogleMailAlreadyExistError: GoogleAuthExpected
      };
    }

    case types.SIGN_IN_USER_OTP_FAILURE: {
      const userError = action.error[0].message;

      const loginError = typeof userError === 'string' && userError.indexOf('AUTHENTICATION FAILED') >= 0;
      return {
        ...state,
        isFetching: false,
        isLoginError: loginError
      };
    }

    case types.CLEAR_LOGIN_USER_FAILURE_ERROR:
    case types.CLEAR_SIGN_IN_USER_OTP_FAILURE_ERROR: {
      return {
        ...state,
        isLoginError: false,
        isGoogleMailAlreadyExistError: false,
        isAppleMailAlreadyExistErrorSocial: false,
        isGoogleMailAlreadyExistErrorSocial: false,
        appleError: false,
        passwordAuthExpected: false
      };
    }

    case REGISTER_USER_SUCCESS: {
      pushDataLayerUserType('register', 'email-register');
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.createUserWithOnetimePassword.user)
        },
        isAuthed: false,
        hasSubscription: false,
        isFetching: false,
        error: undefined,
        isGuest: false,
        isGoogleUser: false,
        openRequests: removeOpenRequest(action, state),
        unconfirmedEmail: action.payload.result.data.createUserWithOnetimePassword.user.unconfirmedEmail
      };
    }

    case types.REQUEST_OTP_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload)
        },
        isAuthed: false,
        hasSubscription: false,
        isFetching: false,
        error: undefined,
        isGuest: false,
        isGoogleUser: false
      };
    }

    case types.SET_USER_AS_AUTHENTICATED: {
      return {
        ...state,
        isAuthed: true,
        isGuest: false
      };
    }

    case PERSIST_HEALTH_INSURANCE_USER_DATA: {
      return {
        ...state,
        updatedAt: Date.now(),
        currentUser: {
          ...state.currentUser,
          ...User(action.payload)
        }
      };
    }

    case REGISTER_USER_FAILURE: {
      const userError = action.error[0].extensions.user;

      const emailHasAlreadyBeenTaken = userError.email && userError.email.indexOf('emailHasAlreadyBeenTaken') !== 1;

      const GoogleAuthExpected = typeof userError === 'string' && userError.indexOf('googleAuthExpected') !== 1;

      const AppleAuthExpected = typeof userError === 'string' && userError.indexOf('appleAuthExpected') !== 1;
      return {
        ...state,
        failedRequests: [...state.failedRequests, action.type],
        openRequests: removeOpenRequest(action, state),
        isFetching: false,
        /// while registering with email we should just show the generic
        /// `Diese E-Mail-Adresse ist bereits registriert.`
        isRegisterError: emailHasAlreadyBeenTaken || GoogleAuthExpected || AppleAuthExpected
      };
    }

    case types.REGISTER_USER_OTP_FAILURE: {
      const userError = action.error[0]?.extensions?.user;
      if (!userError) {
        return {
          ...state,
          isFetching: false,
          isRegisterError: true
        };
      }

      const emailHasAlreadyBeenTaken = userError.email && userError.email.indexOf('emailHasAlreadyBeenTaken') >= 0;

      return {
        ...state,
        isFetching: false,
        /// while registering with email we should just show the generic
        /// `Diese E-Mail-Adresse ist bereits registriert.`
        isRegisterError: emailHasAlreadyBeenTaken
      };
    }

    case types.CLEAR_REGISTER_USER_FAILURE_ERROR: {
      return {
        ...state,
        isFetching: false,
        isRegisterError: false,
        googleRegisterErrorMail: false,
        isGoogleMailAlreadyExistErrorSocial: false
      };
    }

    case REGISTER_USER_GUEST_SUCCESS: {
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state),
        token: action.payload.token,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.user)
        },
        isAuthed: true,
        hasSubscription: false,
        isFetching: false,
        error: undefined,
        isGuest: true,
        unconfirmedEmail: action.payload.user.unconfirmedEmail
      };
    }

    case AUTHENTICATE_WITH_GIS_SUCCESS: {
      pushDataLayerUserType('signin', 'google-reg-signin');

      const token = action.payload.result.data.authenticateWithGIS.token;
      return {
        ...state,
        token: token,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.authenticateWithGIS.user)
        },
        isAuthed: true,
        isFetching: false,
        error: null,
        isGuest: false,
        isGoogleUser: true,
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        unconfirmedEmail: action.payload.result.data.authenticateWithGIS.user.unconfirmedEmail
      };
    }

    case types.LOGIN_APPLE_USER_SUCCESS: {
      pushDataLayerUserType('signin', 'apple-signin');

      const token = action.payload.result.data.signInUserWithAppleToken.token;
      return {
        ...state,
        token: token,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.signInUserWithAppleToken.user)
        },
        isAuthed: true,
        isFetching: false,
        error: undefined,
        isGuest: false,
        isAppleUser: true,
        unconfirmedEmail: action.payload.result.data.signInUserWithAppleToken.user.unconfirmedEmail
      };
    }

    case types.LOGIN_APPLE_USER_FAILURE: {
      const notFound = action.error[0].extensions.user.indexOf('notFound') !== 1;

      const passwordAuthExpected = action.error[0].extensions.user.indexOf('passwordAuthExpected') !== 1;

      const GoogleAuthExpected = action.error[0].extensions.user.indexOf('googleAuthExpected') !== 1;
      return {
        ...state,
        appleError: notFound,
        passwordAuthExpected: passwordAuthExpected,
        isGoogleMailAlreadyExistErrorSocial: GoogleAuthExpected
      };
    }

    case AUTHENTICATE_WITH_GIS_FAILURE: {
      const passwordAuthExpected = action.error[0].extensions.user.indexOf('passwordAuthExpected') !== 1;

      const AppleAuthExpected = action.error[0].extensions.user.indexOf('appleAuthExpected') !== 1;
      return {
        ...state,
        updatedAt: Date.now(),
        openRequests: removeOpenRequest(action, state),
        failedRequests: [...state.failedRequests, action.type],
        passwordAuthExpected: passwordAuthExpected,
        isAppleMailAlreadyExistErrorSocial: AppleAuthExpected
      };
    }

    case types.CHECK_CONFIRM_MAIL_CODE_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...User(action.payload.result.data.confirmEmailAddressChange.user)
        },
        isFetching: false,
        unconfirmedEmail: action.payload.result.data.confirmEmailAddressChange.user.unconfirmedEmail,
        error: undefined
      };
    }
    case types.CHECK_CONFIRM_MAIL_CODE_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };
    }
    case types.CHANGE_REFERER_AFTER_SUCCESS_AUTHENTICATION: {
      return {
        ...state,
        refererAfterAuthenticateUrl: action.payload.pathname
      };
    }
    case types.CLEAR_REFERER_AFTER_SUCCESS_AUTHENTICATION: {
      return {
        ...state,
        refererAfterAuthenticateUrl: ROUTES.profilePersonalData
      };
    }

    case GET_USER_PAYMENT_INFOS_SUCCESS:
      return {
        ...state,
        hasSubscription: action.payload.paymentInfos?.active ?? false
      };

    case REGISTER_USER_GUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        failedRequests: [...state.failedRequests, action.type],
        openRequests: removeOpenRequest(action, state),
        updatedAt: Date.now(),
        error: axiosError(state.error, action)
      };
    }

    case types.GET_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: axiosError(state.error, action)
      };

    case RESET_USER_GUEST_STATE: {
      return {
        ...state,
        isAuthed: true,
        isGuest: true
      };
    }

    default:
      return state;
  }
};
